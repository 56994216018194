<template>
  <div>
    <SimpleHeader></SimpleHeader>
    <div class="login-box">
      <h3 class="title">{{ configObj.appName }}{{ $t(99) }}</h3>
      <div class="tip" v-if="configObj.accountType==='0'">{{ $t(100) }}</div>
      <div class="tab" v-if="configObj.accountType==='0'">
        <span class="item" :class="{active: form.type==='EMAIL'}" @click="form.type='EMAIL'">{{ $t(101) }}</span>
        <span class="item" :class="{active: form.type==='MOBILE'}" @click="form.type='MOBILE'">{{ $t(102) }}</span>
      </div>
      <el-form
        ref="form"
        :rules="rules"
        :model="form">
        <div class="item" v-show="form.type==='EMAIL'">
          <div class="label mb5">{{ $t(101) }}</div>
          <el-form-item prop="email">
            <el-input v-model="form.email">
            </el-input>
          </el-form-item>
        </div>
        <div class="item" v-show="form.type==='MOBILE'">
          <div class="label mb5">{{ $t(102) }}</div>
          <el-form-item
            prop="mobile">
              <el-input v-model="form.mobile">
              <el-select slot="prepend" v-model="form.areaCode" class="code-select" filterable popper-class="bfff">
                <el-option
                  v-for="item in countryList"
                  :key="item.countryCode"
                  :label="'+'+item.areaCode"
                  filterable
                  :value="item.areaCode"
                >{{ item.englishName+'+'+item.areaCode }}</el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </div>
        <div>
          <div class="label mb5">{{ $t(105) }}</div>
          <el-form-item
            prop="password">
              <el-input type="password" v-model="form.password" show-password></el-input>
          </el-form-item>
        </div>
        <div class="pt20 pb10"><el-button type="primary" @click="doSubmit" :loading="loading">{{ $t(94) }}</el-button></div>
        <div class="flex">
          <div class="flex1">
            <el-button type="text" @click="$router.push('/forgot')">{{ $t(103) }}</el-button>
            <el-button type="text" @click="$router.push('/register')">{{ $t(104) }}</el-button>
          </div>
          <el-button type="text" v-if="configObj.isShowLoginService==='1'" @click="showService=true">{{ $t('service') }}</el-button>
        </div>
      </el-form>
      <div class="corpyrights">© 2017 - 2025 {{ configObj.appName }} All rights reserved</div>
    </div>
    <C2cBuy v-model="showService">
      </C2cBuy>
  </div>
</template>

<script>
import loginMixins from '@/mixins/login.js'
import SimpleHeader from '@/components/SimpleHeader'
import C2cBuy from '@/pages/assets/components/C2cBuy'
import { login } from '@/api'
import utils from '@/utils/misc'
export default {
  name: 'Login',
  components: { SimpleHeader, C2cBuy },
  mixins: [loginMixins],
  data() {
    return {
      form: {
        email: '',
        type: window.configObj.accountType === '2'?  'EMAIL' : 'MOBILE',
        mobile: '',
        areaCode: window.configObj.defaultAreaCode,
        password: '',
      },
      showService: false
    }
  },
  computed: {
    configObj() {
      return window.configObj
    },
    rules() {
      return {
        ...this.commonRules
      }
    },
    countryList() {
      return this.$store.state.countryList
    }
  },
  beforeMount() {
    if (this.countryList.length === 0) {
      this.$store.dispatch('getCountries')
    }
    if (this.$route.query.mobile||this.$route.query.email) {
      this.form.type = this.$route.query.mobile ? 'MOBILE' : 'EMAIL'
      this.form.areaCode = this.$route.query.areaCode || this.form.areaCode
      this.form.mobile = this.$route.query.mobile || this.form.mobile
      this.form.email = this.$route.query.email || this.form.email
    }
  },
  methods: {
    doSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const obj = {...this.form}
          login(obj).then(res => {
            utils.setLs('token', res.token)
            this.$store.dispatch('getUserInfo').then(res => {
              this.$router.push(this.$route.query.redirect||'/')
            })
            this.$store.dispatch('getUserAssets')
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-box{
    color: #1E2329;
    width: 400px;
    margin: 10vh auto;
    .title{
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .tab{
      margin: 40px 0px 20px 0px;
      color: #707A8A;
      font-size: 16px;
      .item{
        display: inline-block;
        cursor: pointer;
        padding: 8px 24px;
        margin-right: 12px;
        &.active{
          background-color: #dce1d9;
          color: #1E2329;
          border-radius: 8px;
        }
      }
    }
    .el-input__inner{
      height: 46px;
      line-height: 46px;
    }
  }
  .corpyrights{
    position: fixed;
    bottom: 10px;
    text-align: center;
    font-size: 12px;
    width: 100%;
    left: 0;
  }
</style>
